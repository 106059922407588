import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import HeroHeader from "../../components/HeroHeader";
import Trainers from "../../components/Trainers";
import WorkingAtFEAT from "../../components/WorkingAtFEAT";
import Jobs from "../../components/Jobs";
import Heading from "../../components/Heading";
import TrainerSlides from "../../components/TrainerSlides";

const heroHeaderQuery = graphql`
  query {
    image: file(relativePath: { eq: "about-us-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const workingAtFeatId = "working-at-feat";
const becomeATrainerId = "become-a-feat-trainer";
const meetOurTrainersId = "meet-our-trainers";

export default function JobsPage() {
  const headerImage = useStaticQuery(heroHeaderQuery);
  return (
    <Layout>
      <Seo
        title="Jobs with FEAT Fitness"
        meta_title="Jobs with FEAT Fitness"
        meta_desc="Are you passionate about helping those around you achieve their goals whilst having fun and meeting other people? FEAT Fitness is often hiring as we continue to expand."
        slug="jobs"
      />
      <main>
        <HeroHeader
          title="Jobs at FEAT Fitness"
          background_image={headerImage?.image}
          options={[
            { title: "Working at FEAT Fitness", to: `#${workingAtFeatId}` },
            { title: "Become a FEAT Trainer", to: `#${becomeATrainerId}` },
            { title: "Meet our Trainers", to: `#${meetOurTrainersId}` },
          ]}
        />
        <div className="my-16 text-center">
          <Heading id={workingAtFeatId} level={2} className="mb-16">
            Working at FEAT Fitness
          </Heading>
          <WorkingAtFEAT />
        </div>
        <TrainerSlides />
        <div
          id={becomeATrainerId}
          className="py-16 text-center bg-feat-lightgrey"
        >
          <Jobs />
        </div>
        <div className="my-16 text-center">
          <Heading id={meetOurTrainersId} level={2} className="mb-16">
            Meet our Trainers
          </Heading>
          <Trainers />
        </div>
      </main>
    </Layout>
  );
}
